<script setup lang="ts">
const router = useRouter()
const localePath = useLocalePath()
const { t } = useI18n()
const { getFullSlugPath } = useCollections()

const { mainCollectionChildren } = useMainCollections()

const searchBarFocused = ref(false)
const searchQueryInput = ref('')

async function onSearchBarFocus() {
  searchBarFocused.value = true
  await nextTick()
  const searchBarInput = document.querySelector('[data-testid="searchBarInput"]') as HTMLInputElement
  searchBarInput?.focus()
}

function onSearchBarBlur() {
  searchBarFocused.value = false
}

function clearSearchQuery() {
  searchQueryInput.value = ''
  const searchBarInput = document.querySelector('[data-testid="searchBarInput"]') as HTMLInputElement
  searchBarInput?.focus()
}

function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: { q: searchQueryInput.value },
  })
}
</script>

<template>
  <div data-testid="headerNavigation" class="relative h13 wfull shadow-sm">
    <section class="m-inline-auto hfull max-w-[1920px] wfull flex items-center justify-between px5 p-inline-5 lg:p-inline-7.5 xl:p-inline-15">
      <div data-testid="topCategoryNavigationItems" class="relative left-0 hfull max-w-[calc(100%-42px)]">
        <button data-testid="topCategoryNavigationItems-prev-btn" class="absolute top-0 z1 hfull w8 bg-white shadow-lg hidden -left-5">
          <Icon name="ph:caret-left-bold" class="absolute left-1.25 top-3.25 block h6 w6 shrink-0 grow-0 basis-6" />
        </button>

        <div class="m-auto of-x-scroll of-y-hidden">
          <ul data-testid="topCategoriesContainer" class="m-auto hfull flex items-center ws-nowrap">
            <li
              v-for="(collection, collectionId) in mainCollectionChildren" :key="collectionId"
              data-show-arrow="false"
              :data-testid="`headerNavigationListItem-${collection.name}`"
              class="group relative hfull px-[clamp(1px,.65vw,11px)] pb3.25 pt3.25 text-[clamp(.6875rem,1vw,.875rem)] leading-1.25em first:pl0"
            >
              <NuxtLinkLocale
                :to="`/collections/${getFullSlugPath(collection)}`"
                :data-testid="`linkToUrl-${collection.name}`"
                class="flex"
              >
                <div :data-testid="`label-${collection.name}`" class="relative flex items-center rd-0.5 bg-transparent px1 py1.25 fw600 group-hover:bg-slate-100">
                  {{ collection.name }}
                  <!--                  <Icon name="ph:seal-percent-fill" class="ml1 block h4 w4 shrink-0 grow-0 basis-4 text-red-500" data-testid="saleIcon" /> -->
                </div>
              </NuxtLinkLocale>
            </li>
          </ul>
        </div>

        <button data-testid="topCategoryNavigationItems-next-btn" class="absolute top-0 z1 hfull w8 bg-white shadow-lg hidden -right-px">
          <Icon name="ph:caret-right-bold" class="absolute left-1.25 top-3.25 block h6 w6 shrink-0 grow-0 basis-6" />
        </button>
      </div>

      <div
        data-testid="searchBar"
        class="max-w-unset wfull md:max-w-[294px]"
      >
        <div
          class="top-2 h9 flex items-center rd transition-transform,width,box-shadow duration-75 ease-in md:relative md:top-unset md:of-hidden"
          :class="searchBarFocused
            ? 'absolute right-5 h-[calc(100%-16px)] w-[calc(100%-40px)] md:right-unset md:w-[115%] md:-translate-x-[15%] flex items-center border border-black rd bg-white shadow-[-27px_0_16px_-2px_#fff]'
            : 'border-0 border-slate-100 w10.5 md:wfull md:bg-slate-100'"
        >
          <span
            class="w6 transition-width duration-75 ease-in"
            :class="{ 'md:of-hidden !md:w0': searchBarFocused }"
            @mousedown.prevent="onSearchBarFocus"
          >
            <Icon
              name="ph:magnifying-glass-bold"
              class="mx2 my0 block h4.5 w4.5 shrink-0 grow-0 basis-4 transform-unset cursor-pointer of-hidden md:relative md:left-unset"
              data-testid="searchIcon"
            />
          </span>
          <input
            v-model="searchQueryInput"
            data-testid="searchBarInput"
            :placeholder="t('search.collection_placeholder')"
            :data-is-focused="searchBarFocused"
            class="ml2 wfull text-ellipsis bg-transparent text-[clamp(.6875rem,1vw,.875rem)] md:block focus-visible:outline-none"
            :class="searchBarFocused ? 'bg-white' : 'hidden'"
            @focus="onSearchBarFocus"
            @blur="onSearchBarBlur"
            @keydown.enter.prevent="submitSearch"
          >
          <span
            :class="searchBarFocused && searchQueryInput.length > 0 ? 'op100' : 'op0'"
            class="mr1.25 of-hidden transition-opacity duration-75 ease-in"
            @mousedown.prevent="clearSearchQuery"
          >
            <Icon
              name="ph:x-circle-fill"
              class="mr1 block h4 w4 shrink-0 grow-0 basis-4 cursor-pointer of-hidden text-slate-300"
              data-testid="searchBarCloseIcon"
            />
          </span>
        </div>
      </div>
    </section>
  </div>
</template>
