<template>
  <div data-testid="headerMetaBar" class="h8 wfull bg-accent/50">
    <section class="m-auto hfull max-w-[1920px] wfull flex items-center justify-between bg-accent/50 px0 lg:px7.5 md:px5 xl:px15">
      <div class="hfull flex border-x-px border-input text-xs text-slate">
        <a href="#" class="flex items-center border-r-px border-input px2.5 text-slate-500">
          About Us
        </a>

        <a href="#" class="flex items-center border-r-px border-input px2.5 text-slate-500">
          Reviews
        </a>

        <a href="#" class="flex items-center border-r-px border-input px2.5 text-slate-500">
          Contact & Help
        </a>

        <a href="#" class="flex items-center border-r-px border-input px2.5 text-slate-500">
          Sustainablity
        </a>

        <a href="#" class="flex items-center px2.5 text-slate-500">
          Track My Order
        </a>
      </div>

      <div class="hfull flex border-x-px border-input text-xs text-slate">
        <div class="hfull flex">
          <LanguageButton />
        </div>
      </div>
    </section>
  </div>
</template>
