<script setup lang="ts">
const localePath = useLocalePath()
const drawers = useDrawers()
const priceFormatter = usePriceFormatter()
const { t } = useI18n()
const router = useRouter()

const { activeOrder } = useActiveOrder()

async function handleClose() {
  drawers.value.cart.open = false
  await nextTick()
}

async function redirectToCheckout() {
  await handleClose()
  await router.push(localePath('checkout'))
}

const showCart = computed(() => drawers.value.cart.open)
</script>

<template>
  <NDrawer id="cart-drawer" slide-from="right" :show="showCart" :title="t('cart.label')" closable @close="handleClose">
    <div class="flex flex-col gap4 bg-background p-block-4 md:gap6 md:p-block-6">
      <template v-if="activeOrder?.totalQuantity">
        <CartLineItem
          v-for="line in activeOrder?.lines"
          :key="line.id"
          :line="line"
          :currency="activeOrder?.currency"
          @click:link="handleClose"
        />
      </template>

      <div v-else class="h-48 flex items-center justify-center text-xl text-slate-400">
        {{ t('cart.empty.heading') }}
      </div>
    </div>

    <template v-if="activeOrder?.totalQuantity" #footer>
      <div class="pb-safe">
        <div class="pb6">
          <div class="flex justify-between px4 pt2 text-base text-primary fw600 sm:px6">
            <p v-text="t('order.summary.subtotal.label')" />
            <p v-if="activeOrder.subTotal">
              {{ priceFormatter(activeOrder.subTotal, activeOrder.currency) }}
            </p>
          </div>

          <div class="mt6 px4 sm:px6">
            <UiButton
              data-testid="proceedToCheckoutButton"
              class="mt-2 h-auto w-full px-10 py-4 fw800 leading-none tracking-wide"
              @click="redirectToCheckout"
            >
              {{ t('checkout.label') }}
            </UiButton>
          </div>
        </div>
      </div>
    </template>
  </NDrawer>
</template>
