<template>
  <div class="n-top-bar relative z50 block bg-slate-900 text-white fw600 hidden lg:block">
    <div class="mx-auto h-10 max-w-7xl flex items-center justify-between px-4 lg:(px8) sm:(px6)">
      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <Icon name="ph:package-light" class="mr2 h5.5 w5.5" /> Free shipping
      </span>

      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <Icon name="ph:arrow-u-down-left" class="mr2 h5.5 w5.5" /> 30 day return policy
      </span>

      <span class="inline-flex items-center justify-center fw600 uppercase" n="sm">
        <Icon name="ph:credit-card" class="mr2 h5.5 w5.5" /> secure payments
      </span>
    </div>
  </div>
</template>
