<script setup lang="ts">
const { t } = useI18n()
const ecomail = useEcomail()

function onSubmit(event: any) {
  const { email } = Object.fromEntries(new FormData(event.target as HTMLFormElement).entries()) as { email: string }
  ecomail?.subscribeUser({
    city: '',
    country: '',
    email,
    name: '',
    phone: '',
    street: '',
    surname: '',
    zip: '',
  })
}
</script>

<template>
  <div class="m-auto box-border max-w-3xl flex flex-col items-center justify-center p6">
    <span class="pb4 text-center text-2xl text-primary fw600 leading-[1.3]">
      {{ t('newsletter_subscription.title') }}
    </span>
    <span class="pb4 text-center text-sm text-primary fw600 leading-[1.3]">
      {{ t('newsletter_subscription.description') }}
    </span>

    <form
      class="contents"
      @submit.prevent="onSubmit"
    >
      <div class="wfull p4">
        <div data-testid="inputWrapper" class="relative grow-1">
          <UiInput
            autocomplete="email"
            name="email"
            :placeholder="t('restock_reminder.email.label')"
            type="email"
            class="bg-slate-100"
          />
        </div>
      </div>
      <UiButton :aria-label="t('newsletter_subscription.sign_up')" class="mb8">
        {{ t('newsletter_subscription.sign_up') }}
      </UiButton>
    </form>

    <I18nT
      keypath="newsletter_subscription.disclaimer"
      tag="span"
      class="text-center text-xs text-slate-400/60 fw500 leading-[1.5]"
    >
      <template #siteName>
        EXALTED
      </template>
      <template #privacyPolicyLink>
        <NuxtLinkLocale to="/privacy-policy" class="underline">
          {{ t('page.privacy_policy.title') }}
        </NuxtLinkLocale>
      </template>
      <template #customerSupportEmail>
        <a href="mailto:info@exalted.com" class="underline">
          info@exalted.com
        </a>
      </template>
    </I18nT>
  </div>
</template>
