<script setup lang="ts">

</script>

<template>
  <svg
    id="el_5PXxYT78d" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" width="200" height="73" viewBox="0 0 600 220" style="enable-background:new 0 0 600 220.71;"
    xml:space="preserve"
  >

    <g id="el_kiyi2t_FK1">
      <g id="el_hR9g4sV51v_TKrBKO7Vs">
        <g id="el_hR9g4sV51v_TmQ1JG6v6Q7">
          <path
            id="el_hR9g4sV51v"
            d="M198.6,102.61c-0.32-0.88-0.25-12.44-24.87-19.27S53.64,54.03,0,0c0,0,14.65,76.3,97.77,101.69s84.48,8.56,113.08,41.28&#xA;&#9;&#9;C210.85,142.97,198.92,103.5,198.6,102.61z"
          />
        </g>
      </g>
      <g id="el_mWvcmbTocX_5F1NZAk-a">
        <g id="el_mWvcmbTocX_Jh2FLZod77I">
          <path
            id="el_mWvcmbTocX"
            d="M201.77,140.05c-0.39-0.61-2.37-9.18-21.84-9.93c-19.46-0.74-94.26-0.64-143.56-31.29c0,0,22.71,55.65,88.85,59.88&#xA;&#9;&#9;c66.14,4.23,65.76-10.11,92.73,9.14C217.95,167.84,202.16,140.65,201.77,140.05z"
          />
        </g>
      </g>
      <g id="el_BSqdz7efP8_g2gFSUY70">
        <g id="el_BSqdz7efP8_ia-tsvtRY">
          <path
            id="el_BSqdz7efP8"
            d="M199.37,165.21c-0.35-0.32-3.22-2.51-15.96,0.33c-12.75,2.86-61.23,15.74-98.46,4.31c0,0,24.26,32.2,67.88,23.61&#xA;&#9;&#9;c43.61-8.59,41-20.86,61.8-12.99C214.63,180.46,199.73,165.53,199.37,165.21z"
          />
        </g>
      </g>
      <g id="el_k6faeVRSFB_0HJbYM1jJ">
        <g id="el_k6faeVRSFB_jtjls9_Il">
          <path
            id="el_k6faeVRSFB"
            d="M426.26,83.34c-24.61,6.84-24.54,18.39-24.87,19.27c-0.32,0.89-12.25,40.35-12.25,40.35&#xA;&#9;&#9;c28.6-32.72,29.96-15.88,113.08-41.28C585.35,76.3,600,0,600,0C546.36,54.03,450.88,76.5,426.26,83.34z"
          />
        </g>
      </g>
      <g id="el_K07S6n-_8z_0_fwDGHzk">
        <g id="el_K07S6n-_8z_VQZXjSxk8">
          <path
            id="el_K07S6n-_8z"
            d="M420.07,130.12c-19.46,0.75-21.45,9.33-21.84,9.93c-0.39,0.6-16.18,27.79-16.18,27.79c26.97-19.25,26.59-4.9,92.73-9.14&#xA;&#9;&#9;c66.14-4.23,88.85-59.88,88.85-59.88C514.33,129.48,439.54,129.37,420.07,130.12z"
          />
        </g>
      </g>
      <g id="el_-KwQOPtbnJ_siIzi3Zm-">
        <g id="el_-KwQOPtbnJ_eq-kirfUs">
          <path
            id="el_-KwQOPtbnJ"
            d="M416.59,165.55c-12.75-2.85-15.61-0.66-15.96-0.33s-15.26,15.25-15.26,15.25c20.79-7.86,18.18,4.41,61.8,13&#xA;&#9;&#9;c43.61,8.59,67.88-23.61,67.88-23.61C477.83,181.28,429.34,168.4,416.59,165.55z"
          />
        </g>
      </g>
      <g id="el_IZKnbQVoZx_ZeX93DMYo">
        <g id="el_IZKnbQVoZx">
          <polygon
            id="el_5hRCLBPlYN"
            points="300,54 224.77,183.97 246.56,220.71 300,130.11 353.44,220.71 375.23,183.97 &#9;&#9;"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style lang="postcss">
@-webkit-keyframes el_BSqdz7efP8_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_BSqdz7efP8_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_BSqdz7efP8_ia-tsvtRY_Animation {
  15% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
  100% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
  0% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
}
@keyframes el_BSqdz7efP8_ia-tsvtRY_Animation {
  15% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
  100% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
  0% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px);
    transform: translate(214.6299819946289px, 179.50981903076172px) rotate(-50deg) translate(-214.6299819946289px, -179.50981903076172px)
  }
}
@-webkit-keyframes el_BSqdz7efP8_g2gFSUY70_Animation {
  15% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px)
  }
  100% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px)
  }
}
@keyframes el_BSqdz7efP8_g2gFSUY70_Animation {
  15% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px)
  }
  100% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px);
    transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(90px, -50px)
  }
}
@-webkit-keyframes el_mWvcmbTocX_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_mWvcmbTocX_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_mWvcmbTocX_Jh2FLZod77I_Animation {
  15% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
  100% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
  0% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
}
@keyframes el_mWvcmbTocX_Jh2FLZod77I_Animation {
  15% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
  100% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
  0% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px);
    transform: translate(217.95001220703125px, 167.84999084472656px) rotate(-60deg) translate(-217.95001220703125px, -167.84999084472656px)
  }
}
@-webkit-keyframes el_mWvcmbTocX_5F1NZAk-a_Animation {
  15% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px)
  }
  100% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px)
  }
}
@keyframes el_mWvcmbTocX_5F1NZAk-a_Animation {
  15% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px)
  }
  100% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px);
    transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(80px, -30px)
  }
}
@-webkit-keyframes el_hR9g4sV51v_TmQ1JG6v6Q7_Animation {
  15% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
  100% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
  0% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
}
@keyframes el_hR9g4sV51v_TmQ1JG6v6Q7_Animation {
  15% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
  100% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
  0% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px);
    transform: translate(210.85000610351562px, 142.97000122070312px) rotate(-75deg) translate(-210.85000610351562px, -142.97000122070312px)
  }
}
@-webkit-keyframes el_hR9g4sV51v_TKrBKO7Vs_Animation {
  15% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px)
  }
  100% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px)
  }
}
@keyframes el_hR9g4sV51v_TKrBKO7Vs_Animation {
  15% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px)
  }
  100% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px);
    transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(100px, -20px)
  }
}
@-webkit-keyframes el_hR9g4sV51v_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_hR9g4sV51v_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_k6faeVRSFB_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_k6faeVRSFB_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_k6faeVRSFB_0HJbYM1jJ_Animation {
  15% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px)
  }
  100% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px)
  }
}
@keyframes el_k6faeVRSFB_0HJbYM1jJ_Animation {
  15% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px)
  }
  100% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px);
    transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(-100px, -20px)
  }
}
@-webkit-keyframes el_k6faeVRSFB_jtjls9_Il_Animation {
  15% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
  100% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
  0% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
}
@keyframes el_k6faeVRSFB_jtjls9_Il_Animation {
  15% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
  100% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
  0% {
    -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px);
    transform: translate(389.1400146484375px, 142.95999145507812px) rotate(75deg) translate(-389.1400146484375px, -142.95999145507812px)
  }
}
@-webkit-keyframes el_K07S6n-_8z_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_K07S6n-_8z_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_K07S6n-_8z_0_fwDGHzk_Animation {
  15% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px)
  }
  100% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px)
  }
}
@keyframes el_K07S6n-_8z_0_fwDGHzk_Animation {
  15% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px)
  }
  100% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px);
    transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(-80px, -30px)
  }
}
@-webkit-keyframes el_K07S6n-_8z_VQZXjSxk8_Animation {
  15% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
  100% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
  0% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
}
@keyframes el_K07S6n-_8z_VQZXjSxk8_Animation {
  15% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
  100% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
  0% {
    -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px);
    transform: translate(382.0500183105469px, 167.83999633789062px) rotate(60deg) translate(-382.0500183105469px, -167.83999633789062px)
  }
}
@-webkit-keyframes el_-KwQOPtbnJ_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@keyframes el_-KwQOPtbnJ_Animation {
  25% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
}
@-webkit-keyframes el_-KwQOPtbnJ_eq-kirfUs_Animation {
  15% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
  100% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
  0% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
}
@keyframes el_-KwQOPtbnJ_eq-kirfUs_Animation {
  15% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
  100% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
  0% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px);
    transform: translate(385.3699951171875px, 179.51627349853516px) rotate(50deg) translate(-385.3699951171875px, -179.51627349853516px)
  }
}
@-webkit-keyframes el_-KwQOPtbnJ_siIzi3Zm-_Animation {
  15% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px)
  }
  100% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px)
  }
}
@keyframes el_-KwQOPtbnJ_siIzi3Zm-_Animation {
  15% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px)
  }
  100% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px)
  }
  0% {
    -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px);
    transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(-90px, -50px)
  }
}
@-webkit-keyframes el_IZKnbQVoZx_Animation {
  15% {
    opacity: 0
  }
  25% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
@keyframes el_IZKnbQVoZx_Animation {
  15% {
    opacity: 0
  }
  25% {
    opacity: 1
  }
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
@-webkit-keyframes el_IZKnbQVoZx_ZeX93DMYo_Animation {
  0% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 170px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 170px)
  }
  20% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, -15px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, -15px)
  }
  25% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px)
  }
  100% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px)
  }
}
@keyframes el_IZKnbQVoZx_ZeX93DMYo_Animation {
  0% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 170px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 170px)
  }
  20% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, -15px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, -15px)
  }
  25% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px)
  }
  100% {
    -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
    transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px)
  }
}
#el_5PXxYT78d * {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-timing-function: cubic-bezier(0, 0, 1, 1)
}
#el_IZKnbQVoZx_ZeX93DMYo {
  -webkit-transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
  transform: translate(300.00000762939453px, 137.3550033569336px) translate(-300.00000762939453px, -137.3550033569336px) translate(0px, 0px);
  -webkit-animation-name: el_IZKnbQVoZx_ZeX93DMYo_Animation;
  animation-name: el_IZKnbQVoZx_ZeX93DMYo_Animation;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
}
#el_IZKnbQVoZx {
  opacity: 1;
  -webkit-animation-name: el_IZKnbQVoZx_Animation;
  animation-name: el_IZKnbQVoZx_Animation;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
}
#el_-KwQOPtbnJ_siIzi3Zm- {
  -webkit-animation-name: el_-KwQOPtbnJ_siIzi3Zm-_Animation;
  animation-name: el_-KwQOPtbnJ_siIzi3Zm-_Animation;
  -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px);
  transform: translate(385.3699951171875px, 179.51627349853516px) translate(-385.3699951171875px, -179.51627349853516px) translate(0px, 0px)
}
#el_-KwQOPtbnJ_eq-kirfUs {
  -webkit-animation-name: el_-KwQOPtbnJ_eq-kirfUs_Animation;
  animation-name: el_-KwQOPtbnJ_eq-kirfUs_Animation;
  -webkit-transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px);
  transform: translate(385.3699951171875px, 179.51627349853516px) rotate(0deg) translate(-385.3699951171875px, -179.51627349853516px)
}
#el_-KwQOPtbnJ {
  -webkit-animation-name: el_-KwQOPtbnJ_Animation;
  animation-name: el_-KwQOPtbnJ_Animation;
  opacity: 1
}
#el_K07S6n-_8z_VQZXjSxk8 {
  -webkit-animation-name: el_K07S6n-_8z_VQZXjSxk8_Animation;
  animation-name: el_K07S6n-_8z_VQZXjSxk8_Animation;
  -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px);
  transform: translate(382.0500183105469px, 167.83999633789062px) rotate(0deg) translate(-382.0500183105469px, -167.83999633789062px)
}
#el_K07S6n-_8z_0_fwDGHzk {
  -webkit-animation-name: el_K07S6n-_8z_0_fwDGHzk_Animation;
  animation-name: el_K07S6n-_8z_0_fwDGHzk_Animation;
  -webkit-transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px);
  transform: translate(382.0500183105469px, 167.83999633789062px) translate(-382.0500183105469px, -167.83999633789062px) translate(0px, 0px)
}
#el_K07S6n-_8z {
  -webkit-animation-name: el_K07S6n-_8z_Animation;
  animation-name: el_K07S6n-_8z_Animation;
  opacity: 1
}
#el_k6faeVRSFB_jtjls9_Il {
  -webkit-animation-name: el_k6faeVRSFB_jtjls9_Il_Animation;
  animation-name: el_k6faeVRSFB_jtjls9_Il_Animation;
  -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px);
  transform: translate(389.1400146484375px, 142.95999145507812px) rotate(0deg) translate(-389.1400146484375px, -142.95999145507812px)
}
#el_k6faeVRSFB_0HJbYM1jJ {
  -webkit-animation-name: el_k6faeVRSFB_0HJbYM1jJ_Animation;
  animation-name: el_k6faeVRSFB_0HJbYM1jJ_Animation;
  -webkit-transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px);
  transform: translate(389.1400146484375px, 142.95999145507812px) translate(-389.1400146484375px, -142.95999145507812px) translate(0px, 0px)
}
#el_k6faeVRSFB {
  -webkit-animation-name: el_k6faeVRSFB_Animation;
  animation-name: el_k6faeVRSFB_Animation;
  opacity: 1
}
#el_hR9g4sV51v {
  -webkit-animation-name: el_hR9g4sV51v_Animation;
  animation-name: el_hR9g4sV51v_Animation;
  opacity: 1
}
#el_hR9g4sV51v_TKrBKO7Vs {
  -webkit-animation-name: el_hR9g4sV51v_TKrBKO7Vs_Animation;
  animation-name: el_hR9g4sV51v_TKrBKO7Vs_Animation;
  -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px);
  transform: translate(210.85000610351562px, 142.97000122070312px) translate(-210.85000610351562px, -142.97000122070312px) translate(0px, 0px)
}
#el_hR9g4sV51v_TmQ1JG6v6Q7 {
  -webkit-animation-name: el_hR9g4sV51v_TmQ1JG6v6Q7_Animation;
  animation-name: el_hR9g4sV51v_TmQ1JG6v6Q7_Animation;
  -webkit-transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px);
  transform: translate(210.85000610351562px, 142.97000122070312px) rotate(0deg) translate(-210.85000610351562px, -142.97000122070312px)
}
#el_mWvcmbTocX_5F1NZAk-a {
  -webkit-animation-name: el_mWvcmbTocX_5F1NZAk-a_Animation;
  animation-name: el_mWvcmbTocX_5F1NZAk-a_Animation;
  -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px);
  transform: translate(217.95001220703125px, 167.84999084472656px) translate(-217.95001220703125px, -167.84999084472656px) translate(0px, 0px)
}
#el_mWvcmbTocX_Jh2FLZod77I {
  -webkit-animation-name: el_mWvcmbTocX_Jh2FLZod77I_Animation;
  animation-name: el_mWvcmbTocX_Jh2FLZod77I_Animation;
  -webkit-transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px);
  transform: translate(217.95001220703125px, 167.84999084472656px) rotate(0deg) translate(-217.95001220703125px, -167.84999084472656px)
}
#el_mWvcmbTocX {
  -webkit-animation-name: el_mWvcmbTocX_Animation;
  animation-name: el_mWvcmbTocX_Animation;
  opacity: 1
}
#el_BSqdz7efP8_g2gFSUY70 {
  -webkit-animation-name: el_BSqdz7efP8_g2gFSUY70_Animation;
  animation-name: el_BSqdz7efP8_g2gFSUY70_Animation;
  -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px);
  transform: translate(214.6299819946289px, 179.50981903076172px) translate(-214.6299819946289px, -179.50981903076172px) translate(0px, 0px)
}
#el_BSqdz7efP8_ia-tsvtRY {
  -webkit-animation-name: el_BSqdz7efP8_ia-tsvtRY_Animation;
  animation-name: el_BSqdz7efP8_ia-tsvtRY_Animation;
  -webkit-transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px);
  transform: translate(214.6299819946289px, 179.50981903076172px) rotate(0deg) translate(-214.6299819946289px, -179.50981903076172px)
}
#el_BSqdz7efP8 {
  -webkit-animation-name: el_BSqdz7efP8_Animation;
  animation-name: el_BSqdz7efP8_Animation;
  opacity: 1
}
</style>
