<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

const route = useRoute()

const searchQuery = useRouteQuery('q', '')
const searchQueryInput = ref(searchQuery.value)

const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    searchQueryInput.value = ''
})
</script>

<template>
  <header data-testid="header" class="relative wfull bg-white">
    <HeaderMetaBar class="hidden sm:block" />
    <HeaderTopRowContainer />
    <HeaderNavigation class="hidden sm:block" />
    <UspBanner />
  </header>

  <slot />

  <NavDrawer />
  <CartDrawer />

  <!-- Footer -->
  <footer>
    <LayoutFooter />
  </footer>
</template>
