<script setup lang="ts">
const router = useRouter()
const localePath = useLocalePath()
const { t } = useI18n()

const { wishlistTotalQuantity } = useObserved()
const { orderTotalQuantity } = useActiveOrder()
const drawers = useDrawers()

const { mainCollections, activeMainCollectionSlug, setActiveCollection } = useMainCollections()

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

function openCart() {
  drawers.value.cart.open = true
}
</script>

<template>
  <div data-testid="headerTopRowContainer" class="h16 wfull border-b border-input">
    <section class="relative m-auto h16 wfull flex items-center justify-between px2 lg:px7.5 md:px5 xl:px15">
      <div data-testid="collectionSwitch" class="hfull shrink-2 grow-1 basis-[33%] items-center hidden sm:flex lg:-ml3.5">
        <a
          v-for="(collection, index) in mainCollections" :key="index"
          :data-testid="`collectionSwitch-${index}`"
          :data-active="activeMainCollectionSlug === collection.slug"
          class="relative hfull px2 text-sm c-slate fw600 leading-16 transition-colors duration-500 ease lg:px4.25 n-active:c-slate-900"
          :class="{
            'after:absolute after:inset-x-0 after:bottom-0 after:m-auto after:h.5 after:w6 after:bg-slate-900 after:duration-500 after:ease after:content-[\'\']': activeMainCollectionSlug === collection.slug,
          }"
          href="#"
          @click.prevent="setActiveCollection(collection.slug)"
        >
          {{ collection.name }}
        </a>
      </div>

      <div class="sm:hidden">
        <button
          class="mx2 my0 min-w-6 flex items-center justify-center text-center md:m0 md:gappx"
          @click="handleNavigationClick"
        >
          <span class="relative top-0">
            <Icon name="ph:list" class="block h6 w6 shrink-0 grow-0 basis-6" />
          </span>
        </button>
      </div>

      <div
        data-testid="exalted-logo"
        aria-label="Úvodní stránka"
        class="h8.75 flex flex-row items-center px1"
      >
        <NuxtLinkLocale to="/" class="max-h-8.5 flex items-center gap1">
          <div class="relative min-w-full flex">
            <span data-testid="exaltedLogo" class="relative px1">
              <AnimatedLogo class="h-10 w-auto" />
            </span>
          </div>
        </NuxtLinkLocale>
      </div>

      <div
        data-testid="iconMetaNavigationItems"
        class="relative hfull wfull flex shrink-1 grow-1 basis-[33%] items-center justify-end"
      >
        <div class="relative hfull lg:gap8 md:gap4.5">
          <div class="hfull max-w-full flex items-center gap2 lg:gap8 md:gap4.5">
            <!-- Account -->
            <button
              data-testid="headerUserAccount"
              class="mx2 my0 min-w-6 flex items-center justify-center text-center md:m0 md:gappx"
              aria-label="Přihlásit se"
              @click="router.push(localePath('/account'))"
            >
              <span class="relative top-0">
                <Icon name="ph:user" class="block h6 w6 shrink-0 grow-0 basis-6" />
              </span>
            </button>

            <!-- Wishlist -->
            <button
              data-testid="headerWishlist"
              class="mx2 my0 min-w-6 flex items-center justify-center text-center md:m0 md:gappx"
              :aria-label="t('wishlist.label')"
              @click="router.push(localePath('/wishlist'))"
            >
              <span class="relative top-0">
                <!--                <span -->
                <!--                  v-if="wishlistTotalQuantity" -->
                <!--                  data-testid="badgeDot" -->
                <!--                  class="absolute top-0.5 z1 h1.75 min-h-1.75 min-w-1.75 w1.75 inline-flex items-center justify-center rounded-1/2 bg-red text-center -right-0.25" -->
                <!--                > -->
                <!--                  <span color="White" class="text-[0.5rem] text-white fw500" /> -->
                <!--                </span> -->
                <Icon name="ph:heart-straight" class="block h6 w6 shrink-0 grow-0 basis-6" />
              </span>

              <ClientOnly>
                <span v-if="wishlistTotalQuantity" data-testid="numberBadge" class="text-2.5 fw700 md:leading-2.5">
                  {{ wishlistTotalQuantity }}
                </span>
              </ClientOnly>
            </button>

            <!-- Cart -->
            <button
              data-testid="headerCart"
              class="mx2 my0 min-w-6 flex items-center justify-center text-center md:m0 md:gappx"
              :aria-label="t('cart.label')"
              @click="openCart"
            >
              <span class="relative top-0">
                <!--                <span -->
                <!--                  v-if="orderTotalQuantity" -->
                <!--                  data-testid="badgeDot" -->
                <!--                  class="absolute right-0 top-0.5 z1 h1.75 min-h-1.75 min-w-1.75 w1.75 inline-flex items-center justify-center rounded-1/2 bg-red text-center" -->
                <!--                > -->
                <!--                  <span color="White" class="text-[0.5rem] text-white fw500" /> -->
                <!--                </span> -->
                <Icon name="ph:bag-simple" class="block h6 w6 shrink-0 grow-0 basis-6" />
              </span>
              <ClientOnly>
                <span v-if="orderTotalQuantity" data-testid="numberBadge" class="text-2.5 fw700 md:leading-2.5">
                  {{ orderTotalQuantity }}
                </span>
              </ClientOnly>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
