<script setup lang="ts">
import type { CollectionListItemFragment } from '#graphql-operations'

export type CollectionListItemFragmentWithChildren = CollectionListItemFragment & { children: { [id: string]: CollectionListItemFragmentWithChildren } }

const router = useRouter()
const localePath = useLocalePath()
const drawers = useDrawers()
const { t } = useI18n()

const { wishlistTotalQuantity } = useObserved()
const { orderTotalQuantity } = useActiveOrder()
const { getFullSlugPath, rootCollections, collectionTree, fetchChildrenOfCollection } = useCollections()
const { mainCollections } = useMainCollections()

onMounted(async () => {
  // Populate root collections' children in the collection tree.
  const rootCollectionIds = rootCollections.value.map(c => c.id)
  await fetchChildrenOfCollection(rootCollectionIds)

  // Directly get the children IDs from the root collections from the collection tree and fetch their children.
  const childrenIds = rootCollections.value.reduce((acc, collection) => {
    const childKeys = Object.values(collectionTree.value[collection.slug].children).map(c => c.id)
    acc.push(...childKeys)
    return acc
  }, [] as string[])

  await fetchChildrenOfCollection(childrenIds)
})

const currentCollection = ref<CollectionListItemFragmentWithChildren | undefined>(undefined)
const currentRootCollectionId = ref<string>('all')
const searchQueryInput = ref('')

const currentRootCollection = computed(() => collectionTree.value[currentRootCollectionId.value])
const showFirstLevelCollectionNavigation = computed(() => !currentCollection.value)
function handleFirstLevelCollectionNavigationClick(collection: CollectionListItemFragmentWithChildren) {
  currentCollection.value = collection
}

function handleCollectionClickBack() {
  currentCollection.value = undefined
}

function onAfterLeave() {
  handleCollectionClickBack()
  if (searchQueryInput.value)
    searchQueryInput.value = ''
  currentRootCollectionId.value = 'all'
}

async function handleSecondLevelCollectionClick(collection: CollectionListItemFragmentWithChildren) {
  handleClose()
  try {
    await router.push(localePath(`/collections/${getFullSlugPath(collection)}`))
  }
  catch (err) {
    console.error(`Navigation to collection ${collection.slug} failed`, err)
  }
}

function handleClose() {
  drawers.value.navigation.open = false
}

function openCart() {
  handleClose()
  drawers.value.cart.open = true
}

function openAccount() {
  handleClose()
  navigateTo(localePath('/account'))
}

function openWishlist() {
  handleClose()
  navigateTo(localePath('/wishlist'))
}

async function handleSearchSubmit() {
  let path = localePath('/search')

  if (currentRootCollectionId.value !== 'all')
    path = localePath(`/collections/${getFullSlugPath(currentRootCollection.value)}`)

  handleClose()
  try {
    await router.push({ path, query: { q: searchQueryInput.value } })
  }
  catch (err) {
    console.error('Search failed', err)
  }
}
</script>

<template>
  <NDrawer
    id="menu-drawer"
    title="Menu"
    slide-from="left"
    :show="drawers.navigation.open"
    closable
    as="aside"
    @close="handleClose"
    @after-leave="onAfterLeave"
  >
    <template #header>
      <div>
        <header class="sticky top-0 z2 h16 flex shrink-0 grow-0 basis-[14rem] bg-white shadow-sm">
          <div class="hfull flex grow-1 items-center justify-between of-hidden pl4">
            <div class="flex shrink-1 grow-1 basis-[0%] gap1.25">
              <NuxtLinkLocale data-testid="link" class="flex grow-1" to="/account" @click.prevent="openAccount">
                <button
                  data-testid="userNav"
                  class="h12 flex grow-1 flex-col items-center justify-center rd-1 bg-accent/50 p0"
                >
                  <Icon name="ph:user" class="block h6 w6 shrink-0 grow-0 basis-6" />
                  <span data-testid="offCanvasLogin" class="max-w-[15ch] of-hidden text-ellipsis text-xs fw-bold">
                    {{ t('form.sign_in.label') }}
                  </span>
                </button>
              </NuxtLinkLocale>

              <NuxtLinkLocale data-testid="link" class="flex grow-1" to="/wishlist" @click.prevent="openWishlist">
                <button
                  data-testid="offCanvasToWishlist"
                  class="h12 flex grow-1 flex-col items-center justify-center rd-1 bg-accent/50 p0"
                >
                  <div class="flex items-center justify-center">
                    <span class="relative top-0 box-border">
                      <Icon name="ph:heart-straight" class="block h6 w6 shrink-0 grow-0 basis-6" />
                    </span>
                    <span v-if="wishlistTotalQuantity" class="ml0.5 text-2.5 fw700">
                      {{ wishlistTotalQuantity }}
                    </span>
                  </div>
                  <span class="max-w-[15ch] of-hidden text-ellipsis text-xs fw-bold">
                    {{ t('wishlist.label') }}
                  </span>
                </button>
              </NuxtLinkLocale>

              <a data-testid="link" class="flex grow-1" href="#" @click.prevent="openCart">
                <button
                  data-testid="offCanvasToCart"
                  class="h12 flex grow-1 flex-col items-center justify-center rd-1 bg-accent/50 p0"
                >
                  <div class="flex items-center justify-center">
                    <span class="relative top-0 box-border">
                      <Icon name="ph:bag-simple" class="block h6 w6 shrink-0 grow-0 basis-6" />
                    </span>
                    <span v-if="orderTotalQuantity" class="ml0.5 text-2.5 fw700">
                      {{ orderTotalQuantity }}
                    </span>
                  </div>
                  <span class="max-w-[15ch] of-hidden text-ellipsis text-xs fw-bold">
                    {{ t('cart.label') }}
                  </span>
                </button>
              </a>
            </div>
          </div>

          <button data-testid="drawerCloseIcon" class="flex items-center justify-center p5" @click="handleClose">
            <NIcon icon="i-heroicons:x-mark" n="lg" />
          </button>
        </header>
      </div>
    </template>

    <section class="flex-1 px5 pb2.5">
      <RdxTabsRoot v-model="currentRootCollectionId" class="flex flex-col" default-value="all">
        <RdxTabsList
          v-if="showFirstLevelCollectionNavigation"
          class="relative flex shrink-0 overflow-y-auto border-b n-border-base px5 -mx5"
          aria-label="Collections"
        >
          <RdxTabsIndicator
            class="absolute bottom-0 left-0 h0.75 w-[--radix-tabs-indicator-size] translate-x-[--radix-tabs-indicator-position] rounded-full transition-all duration-150"
          >
            <div class="h-full w-full bg-slate-900" />
          </RdxTabsIndicator>

          <RdxTabsTrigger
            class="mr5 h13 flex cursor-default select-none items-center justify-center rounded-tl-md text-base leading-none outline-none hover:text-slate-500 n-text-body !n-active:text-slate-900 !n-active:fw700"
            value="all"
          >
            {{ t('collections.all') }}
          </RdxTabsTrigger>
          <RdxTabsTrigger
            v-for="collection in mainCollections"
            :key="collection.slug"
            class="mr5 h13 flex cursor-default select-none items-center justify-center rounded-tl-md text-base leading-none outline-none hover:text-slate-500 n-text-body !n-active:text-slate-900 !n-active:fw700"
            :value="collection.slug"
          >
            {{ collection.name }}
          </RdxTabsTrigger>
        </RdxTabsList>
        <!--        <NuxtLinkLocale to="/search" /> -->

        <form
          v-if="showFirstLevelCollectionNavigation"
          @submit.prevent="handleSearchSubmit"
        >
          <div class="mt4 flex items-center border n-border-base rd bg-slate100 px2 focus-within:n-focus-base focus-within:border-context">
            <NIcon icon="i-heroicons:magnifying-glass" class="mr-0.4em text-1.1em op50" />
            <input
              v-model="searchQueryInput"
              type="search"
              class="wfull flex-auto bg-transparent py2.5 fw600 !outline-none"
              :placeholder="t('search.collection_placeholder')"
            >
          </div>
        </form>

        <div class="mt6 -my5" />

        <RdxTabsContent class="relative flex of-hidden" value="all">
          <Transition
            enter-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            enter-from-class="transform op0 translate-x-[-20%] absolute top-0"
            enter-to-class="op100 translate-x-0 top-0"
            leave-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            leave-from-class="op100 translate-x-0 top-0"
            leave-to-class="transform op0 translate-x-[-20%] absolute top-0"
          >
            <div
              v-if="showFirstLevelCollectionNavigation"
              :data-active="showFirstLevelCollectionNavigation"
            >
              <nav class="mb2.5 mt6">
                <menu
                  class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]"
                  data-testid="firstLevelCollectionNavigation"
                >
                  <CollectionTreeNavigationItem
                    v-for="(value, key) in collectionTree" :key="`all-${key}`"
                    :collection="value"
                    as="li"
                    prevent
                    @click="handleFirstLevelCollectionNavigationClick"
                  />
                </menu>
              </nav>
            </div>

            <div
              v-else
              :data-active="!showFirstLevelCollectionNavigation"
              class="top-0"
            >
              <nav v-if="!showFirstLevelCollectionNavigation">
                <div class="mr6 flex justify-between py4.5">
                  <button
                    data-testid="collectionNavigationBack"
                    class="cursor-pointer appearance-none border-unset bg-transparent p0"
                    @click="handleCollectionClickBack"
                  >
                    <NIcon icon="i-ri:arrow-left-s-line" class="block shrink-0 grow-0 text-2xl" />
                  </button>
                  <span class="line-clamp-2 grow-1 text-center text-xl fw-bold">
                    {{ currentCollection?.name }}
                  </span>
                </div>
                <menu data-testid="secondLevelCollectionNavigation" class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]">
                  <!-- Add a show all collection which shows all, within the current collection -->
                  <CollectionTreeNavigationItem :collection="currentCollection" as="li">
                    <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-slate-900 fw600 leading-snug hyphens-auto">
                      {{ t('general.show_all') }}
                    </span>
                  </CollectionTreeNavigationItem>

                  <CollectionTreeNavigationItem
                    v-for="(value, key) in currentCollection?.children" :key="`all-${key}`"
                    :collection="value"
                    as="li"
                    @click="handleSecondLevelCollectionClick"
                  />
                </menu>
              </nav>
            </div>
          </Transition>
        </RdxTabsContent>

        <RdxTabsContent
          v-for="collection in mainCollections"
          :key="collection.slug"
          class="relative flex of-hidden"
          :value="collection.slug"
        >
          <Transition
            enter-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            enter-from-class="transform op0 translate-x-[-20%] absolute top-0"
            enter-to-class="op100 translate-x-0 top-0"
            leave-active-class="duration-150 ease-[cubic-bezier(0.81,0.08,0.68,0.53)] top-0"
            leave-from-class="op100 translate-x-0 top-0"
            leave-to-class="transform op0 translate-x-[-20%] absolute top-0"
          >
            <div
              v-if="showFirstLevelCollectionNavigation"
              :data-active="showFirstLevelCollectionNavigation"
            >
              <!--            :class="showFirstLevelCollectionNavigation ? 'op100 relative wfull translate-x-0' : 'top-0 absolute op0 translate-x-[-20%]'" -->
              <nav class="mb2.5 mt6">
                <menu
                  class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]"
                  data-testid="firstLevelCollectionNavigation"
                >
                  <CollectionTreeNavigationItem
                    v-for="(value, key) in collectionTree[collection.slug].children" :key="`${collection.slug}-${key}`"
                    :collection="value"
                    as="li"
                    prevent
                    @click="handleFirstLevelCollectionNavigationClick"
                  />
                </menu>
              </nav>
            </div>

            <div
              v-else
              :data-active="!showFirstLevelCollectionNavigation"
            >
              <nav v-if="!showFirstLevelCollectionNavigation">
                <div class="mr6 flex justify-between py4.5">
                  <button
                    data-testid="collectionNavigationBack"
                    class="cursor-pointer appearance-none border-unset bg-transparent p0"
                    @click="handleCollectionClickBack"
                  >
                    <NIcon icon="i-ri:arrow-left-s-line" class="block shrink-0 grow-0 text-2xl" />
                  </button>
                  <span class="line-clamp-2 grow-1 text-center text-xl fw-bold">
                    {{ currentCollection?.name }}
                  </span>
                </div>
                <menu data-testid="secondLevelCollectionNavigation" class="pointer-events-auto grid grid-cols-[repeat(1,1fr)] m0 list-none gap1 p0 op100 xs:grid-cols-[repeat(2,1fr)]">
                  <CollectionTreeNavigationItem :collection="currentCollection" as="li">
                    <span class="line-clamp-2 grow-1 items-center text-ellipsis break-anywhere text-slate-900 fw600 leading-snug hyphens-auto">
                      {{ t('general.show_all') }}
                    </span>
                  </CollectionTreeNavigationItem>

                  <CollectionTreeNavigationItem
                    v-for="(value, key) in currentCollection?.children" :key="`${collection.slug}-${key}`"
                    :collection="value"
                    as="li"
                    @click="handleSecondLevelCollectionClick"
                  />
                </menu>
              </nav>
            </div>
          </Transition>
        </RdxTabsContent>
      </RdxTabsRoot>

      <div
        v-if="showFirstLevelCollectionNavigation"
        data-testid="actionContainer"
        class="flex justify-between gap1 border-t n-border-base px5 pb3 pt8 -mx5"
      >
        <LanguageCountrySwitch />
      </div>
    </section>
  </NDrawer>
</template>
